import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
import { HashLink } from '../components/link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`VueJS First Impressions`}</h1>
    <p>{`In September I made the jump from React to Vue. This post is just me thinking aloud about my first impressions.`}</p>
    <h2><HashLink id="docs" to="/vue#docs" mdxType="HashLink">{`Documentation`}</HashLink></h2>
    <p>{`I’ve been spoiled throughout the years when it comes to React documentation. It’s hard for other libraries and frameworks to stack up to the level of quality and completeness of the React docs. But I have to say that the Vue docs did not disappoint!`}</p>
    <p>{`I found that I didn’t need a tutorial to get started with Vue. The `}<a parentName="p" {...{
        "href": "https://vuejs.org/v2/guide/"
      }}>{`VueJS guide documentation`}</a>{` was fantastic in getting me up and running efficiently. I loved the interactive nature of the guide where folks can open the developer console and actually make changes to the Vue code that’s used to render the documentation itself 👌.`}</p>
    <p>{`Also, as a Vue beginner, I find myself often referencing `}<a parentName="p" {...{
        "href": "https://vuejs.org/v2/api/"
      }}>{`the VueJS V2 API docs`}</a>{`.`}</p>
    <h2><HashLink id="cli" to="/vue#cli" mdxType="HashLink">{`Vue CLI`}</HashLink></h2>
    <p>{`The first thing that comes to mind when I think about starting to learn Vue is how impressed I quickly became with the CLI. The CLI features save time and drastically improve developer experience.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ npm i @vue/cli -g
`}</code></pre>
    <p>{`Similarly to `}<inlineCode parentName="p">{`create-react-app`}</inlineCode>{` we can use the Vue CLI to provision a new application.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ vue create my-app-name
`}</code></pre>
    <p>{`The difference is that the Vue CLI will ask us questions about configuration preferences, common libraries we’d like to include in our app, etc.`}</p>
    <p>{`This is `}<strong parentName="p">{`very`}</strong>{` nice.`}</p>
    <p>{`With React, I’d have to go out and install all of my dependencies one by one (which isn’t the end of the world) but then I’d have to integrate the libraries in my application. The Vue CLI does most of this for us. Definitely a time saver.`}</p>
    <h2><HashLink id="slots" to="/vue#slots" mdxType="HashLink">{`Slots`}</HashLink></h2>
    <p>{`For those of us who are familiar with React, we can think about a `}<inlineCode parentName="p">{`slot`}</inlineCode>{` as `}<inlineCode parentName="p">{`children`}</inlineCode>{` of a React component.`}</p>
    <p>{`As an example, imagine we have a `}<inlineCode parentName="p">{`<navigation-link>`}</inlineCode>{` component:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<a v-bind:href="url" class="nav-link">
  <slot></slot>
</a>
`}</code></pre>
    <p>{`We could add content inside the slot definition itself as a fallback in case the parent component doesn't provide slot content. It's parent component might look something like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<navigation-link url="/profile">
  <font-awesome-icon name="user"></font-awesome-icon>
  Your Profile
</navigation-link>
`}</code></pre>
    <p>{`In Vue land we can also use `}<a parentName="p" {...{
        "href": "https://vuejs.org/v2/guide/components-slots.html#Named-Slots"
      }}>{`named slots`}</a>{` which are a game changer. React devs, no more passing components from a parent to a child via props.`}</p>
    <h2><HashLink id="css" to="/vue#css" mdxType="HashLink">{`Scoped CSS support`}</HashLink></h2>
    <p>{`Usually, in the React world I’d reach for Styled Components or Emotion to handle my CSS scoping and CSS-in-JS needs. In the Vue world scoping CSS to a component as well as JS interop is available out-of-the-box.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<template>
  <p>{{ greeting }} World!</p>
</template>

<script>
module.exports = {
  data: function() {
    return {
      greeting: 'Hello',
    };
  },
};
</script>

<style scoped>
p {
  font-size: 2em;
  text-align: center;
}
</style>
`}</code></pre>
    <p>{`This is awesome but one thing that still makes me scratch my head is that `}<inlineCode parentName="p">{`style`}</inlineCode>{` blocks in a Single File Component (SFC) are globally scoped by default. It seems to me that scoping CSS to an SFC would be a much more common use case. In my opinion, instead of adding the `}<inlineCode parentName="p">{`scoped`}</inlineCode>{` attribute to our `}<inlineCode parentName="p">{`style`}</inlineCode>{` tag almost every time we write a new SFC we should be opting into global styling instead.`}</p>
    <h2><HashLink id="conclusion" to="/vue#conclusion" mdxType="HashLink">{`Liking the Vue from up here`}</HashLink></h2>
    <p>{`Overall, I’ve enjoyed learning more about VueJS. Mileage may vary but I’ve found that getting productive with Vue has been fairly painless coming from a React background.`}</p>
    <p>{`I’m looking forward to learning more about Vue (composition API, I’m looking at you). I’m also excited to build more features with it.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      